
import Vue from 'vue'

export default Vue.extend({
  name: 'FormSelect',

  data: () => {
    return {
      err: '',
      altOptions: null
    };
  },

  props: [
    'dependency',
    'dependencyValue',
    'errors',
    'label',
    'name',
    'options',
    'placeholder',
    'value',
    'validation',
  ],

  computed: {
    isDisabled () {
      const opt: any = this.options
      let dep: boolean = typeof this.dependency !== 'undefined'
      if (dep && this.dependencyValue) {
        const key: string = (this.dependencyValue as string).toLowerCase();
        if (typeof opt[key] !== 'undefined') {
          dep = false
        }
      }
      return dep
    },
    compOptions () {
      let opt: any = this.options
      const dep: boolean = typeof this.dependency !== 'undefined'
      if (dep && this.dependencyValue) {
        const key: string = (this.dependencyValue as string).toLowerCase()
        if (typeof opt[key] !== 'undefined') {
          opt = opt[key].map((o: any) => {
            return {value: o, name: o}
          });
        } else {
          opt = []
        }
      }
      return opt
    },
  },

  methods: {
    checkErrors(errors: any, value: string) {
      if (errors && errors.length > 0) {
        let e = errors.filter((err: any) => {
          if (err.field) {
            if (this.name === err.field) {
              return true
            } else {
              const regex = err.field.match(/{([^}]+)}/) 
              if (regex && regex[1] && regex[1].split(':')[1] === value) return true
            }
          } 
          return false
        })
        if (e.length > 0) return 'error'
      }
    },
    updateInput(val: any) {
      // console.log('updateInput -> val', val);
      this.$emit('input', val)
    }
  },
})
