<template>
  <div id="header" v-if="!fullscreenMode">
    <!--
    <h4 v-if="currentAward && !darkMode && !pressMode && !juryMode && !exportMode" 
        :class="[user ? 'loggedin' : '']" v-html="currentAward.title"></h4>
    -->
    <div v-if="!darkMode" class="byak-logos">
      <img src="../assets/img/logo.svg" alt="Bayerische Architektenkammer" />
      <span v-if="currentAward" class="awardsponsors">
        <img v-for="s in currentAward.awardSponsors" :src="imgUrl + s.imagePath + '?h=65'" :key="s.id" :alt="s.name" />
      </span>
    </div>
    <h2 v-else class="text-logo">Administration Bayerische Architektenkammer</h2>
    <template v-if="user">
      <div id="nav" v-if="$mq !== 'sm'">
        <span v-if="user">
          Hallo <strong class="pr-1">{{user.email}}</strong>
          <unicon name=user width=16 height=16></unicon>
        </span>
        <br/>
        <span v-if="isAdmin">
          <template v-if="!exportMode">
            <router-link to="/export/index">Export</router-link> |
          </template>
          <template v-if="!juryMode">
            <router-link to="/jury/index">Jury</router-link> |
          </template>
          <template v-if="!pressMode">
            <router-link to="/presse/index">Presse</router-link> |
          </template>
          <router-link to="/admin">Administration</router-link> |
        </span>
        <span v-if="!exportMode && !juryMode && !pressMode">
          <router-link to="/">Start</router-link> |
        </span>
        <span v-if="!user">
          <router-link to="/anmelden">Anmelden</router-link>
        </span>
        <span v-else-if="exportMode">
          <router-link to="/export/index">Übersicht</router-link> |
          <router-link to="/export/abmelden">Abmelden</router-link>
        </span>
        <span v-else-if="juryMode">
          <router-link to="/jury/index">Übersicht</router-link> |
          <router-link to="/jury/abmelden">Abmelden</router-link>
        </span>
        <span v-else-if="pressMode">
          <router-link to="/presse/index">Übersicht</router-link> |
          <router-link to="/presse/abmelden">Abmelden</router-link>
        </span>
        <span v-else>
          <router-link to="/anmelden/index">Übersicht</router-link> |
          <router-link to="/anmelden/abmelden">Abmelden</router-link>
        </span>
      </div>
      <div v-else class="mobile-menu">
        <div class="toggle" @click.stop="menu = !menu" v-click-outside="closeMenu">
          <unicon v-if="!menu" name="bars"></unicon>
          <unicon v-else name="multiply"></unicon>
        </div>
        <transition name="slide-fade">
          <ul class="mobile-menu" v-if="menu">
            <li>
              <span v-if="user">
                <unicon name=user width=16 height=16></unicon>
                Hallo 
                <strong>{{user.firstname}} {{user.lastname}}</strong> 
              </span>
            </li>
            <li v-for="o in menuOptions" :key="o.text" 
                v-show="!o.user || (o.user === 'yes' && user) || (o.user === 'no' && !user) || 
                        (o.user === 'admin' && isAdmin) || (o.user === 'press' && (isPress || isAdmin))">
              <router-link :to="o.to">{{ o.text }}</router-link>
            </li>
          </ul>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Header',

  data() {
    return {
      menu: false,
      menuOptions: [
        { to: '/', icon: '', text: 'Startseite', },
        { to: '/presse/index', icon: '', text: 'Pressebereich', user: 'press'},
        { to: '/admin', icon: '', text: 'Administration', user: 'admin'},
        { to: '/anmelden', icon: '', text: 'Anmelden', user: 'no'},
        { to: '/anmelden/index', icon: '', text: 'Übersicht', user: 'yes'},
        { to: '/anmelden/abmelden', icon: '', text: 'Abmelden', user: 'yes'},
      ],
    }
  },

  props: {
    msg: String,
  },

  computed: {
    currentAward () {
      return this.$store.state.currentAward
    },
    darkMode () { 
      return this.$store.state.darkMode
    },
    imgUrl () {
      return this.$store.state.baseUrl + 'images/'
    },
    isAdmin () { 
      return this.$store.state.user?.roles?.includes('ROLE_ADMIN')
    },
    isExport () { 
      return this.$store.state.user?.roles?.includes('ROLE_EXPORT')
    },
    isJury () { 
      return this.$store.state.user?.roles?.includes('ROLE_JURY')
    },
    isPress () { 
      return this.$store.state.user?.roles?.includes('ROLE_PRESS')
    },
    exportMode () { 
      return this.$store.state.exportMode
    },
    fullscreenMode () { 
      return this.$store.state.fullscreenMode
    },
    juryMode () { 
      return this.$store.state.juryMode
    },
    pressMode () { 
      return this.$store.state.pressMode
    },
    user () { 
      return this.$store.state.user
    },
  },

  methods: {
    closeMenu() {
      this.menu = false
    },
  },
}
</script>

<style lang="less">
#header {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  padding: 10px 0;

  > h4 {
    position: absolute;
    right: 102%;
    top: 82px;
    width: fit-content;
    transform: rotateZ(-90deg);
    transform-origin: right 30px;
    color: lighten(@font-color, 20%);
    z-index: 2;

    &.loggedin {
      top: 168px;
      display: none;
    }
  }

  .text-logo {
    float: left;
    text-align: left;
    max-width: 320px;
    padding-left: 10px;
    @media all and (min-width: 1000px) {
      padding-left: 120px;
    }
  }

  .awardsponsors {
    img {
      margin-left: 90px;
    }
  }

  .byak-logos {
    height: 65px;
    padding-left: 10px;
    padding-top: 15px;
    text-align: left;

    img {
      height: 100%;

      &.middle {
        left: 240px;
      }

      &.right {
        left: auto;
        right: 0;
      }
    }
  }

  .unicon {
    position: relative;
    padding: 0 1px;
    svg {
      position: relative;
      top: 1px;
    }
  }
  
  #nav {
    padding: 20px 10px;
    text-align: right;

    a {
      text-decoration: none;
      color: @font-color;

      &.router-link-exact-active {
        color: @primary;
      }
    }
  }

  .mobile-menu {
    position: absolute;
    z-index: 99;
    min-width: 150px;
    min-height: 50px;
    top: 10px;
    right: 10px;

    > .toggle {
      float: right;
    }

    ul {
      position: absolute;
      background: #fff;
      box-shadow: 1px -1px 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
      margin: 0;
      top: 30px;
      right: 0;
      list-style: none;
    }

    li {
      padding: @spacing-v @spacing-h;

      a {
        text-decoration: none;
        color: @font-color;

        &.router-link-exact-active {
          color: @primary;
        }
      }
    }
  }
}
</style>
