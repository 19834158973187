
import Vue from 'vue'

export default Vue.extend({
  name: 'Start',

  computed: {
    awards () {
      return this.$store.state.appInfo ? this.$store.state.appInfo.awards.activeAwards : null
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    upcomingAwards () {
      return this.$store.state.appInfo ? this.$store.state.appInfo.awards.upcomingAwards : null
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
  },

  methods: {
    switchContext (val: any) {
      if (!val || (this.currentAward && val === this.currentAward.id)) {
        return this.$store.commit('setCurrentAward', null)
      }
      
      let res = this.awards?.filter((aw: any) => aw.id == val)[0]
      if (res) {
        this.$store.commit('setCurrentAward', res)
      }
    },
    showPreview (award: any) {
      if (!award) return this.$store.commit('setDialog', null)
      this.$store.commit('setDialog', {
        title: award.title,
        content: award.description,
        callback: () => {  }
      })
    },
  },

  created () {
    this.$store.commit('setDarkMode', false)
    const { awardType, year } = this.$route.query
    // allows URLs with query params like '?awardType=architektouren&year=2021'
    if (awardType && year) {
      let res = this.awards?.filter((aw: any) => (aw.awardType == awardType && aw.year == year))[0]
      if (res && (!this.currentAward || res.id !== this.currentAward.id)) {
        this.switchContext(res.id)
      }
    }
  },
})
