
import Vue from 'vue'

export default Vue.extend({
  name: 'FormInput',

  data: () => {
    return {
      err: '',
    }
  },

  props: [
    'name',
    'label',
    'value',
    'type',
    'validation',
  ],

  methods: {
    updateInput(val: any) {
      this.$emit('input', val)
    },
  },
})
