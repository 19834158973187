<template>
  <div id="app" :class="[
    darkMode ? 'dark-ui' : null,
    exportMode ? 'export-ui' : null,
    juryMode ? 'jury-ui' : null,
    pressMode ? 'press-ui' : null
  ]">
    <transition name="fade">
      <div v-if="processing" class="processing">
        <unicon name="process" class="spin" />
      </div>
    </transition>
    <transition name="bounce">
      <ui-dialog v-if="dialog !== null" :dialog="dialog"></ui-dialog>
    </transition>
    <Header/>
    <div class="content" :class="[fullscreenMode ? 'fullscreen' : '']">
      <transition name="bounce">
        <div v-if="alert !== null" class="alert" :class="alert.type">
          <button class="right icon" @click="closeAlert()"><unicon name="multiply"></unicon></button>
          <unicon class="left" v-if="alert.type === 'error'" name="exclamation-triangle" fill="red"></unicon>
          <unicon class="left" v-else-if="alert.type === 'info'" name="info-circle" fill="#333"></unicon>
          <div class="px-10" v-html="alert.message" />
        </div>
      </transition>
      <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>
// polyfills
import 'whatwg-fetch'

// Components
import Footer from './components/Footer'
import Header from './components/Header'

export default {
  name: 'App',

  components: {
    Footer,
    Header,
  },

  data() {
    return {
      context: null,
    };
  },

  computed: {
    alert () {
      return this.$store.state.alert
    },
    darkMode () {
      return this.$store.state.darkMode
    },
    dialog () {
      return this.$store.state.dialog
    },
    exportMode () {
      return this.$store.state.exportMode
    },
    fullscreenMode () {
      return this.$store.state.fullscreenMode
    },
    juryMode () {
      return this.$store.state.juryMode
    },
    pressMode () {
      return this.$store.state.pressMode
    },
    processing () {
      return this.$store.state.processing
    },
    user () {
      return this.$store.state.user
    },
  },

  methods: {
    async logout () {
      const yes = confirm("Wollen Sie sich wirklich abmelden?")
      if (yes) {
        this.$store.commit('setUser', null)
        this.$router.push('/')
        // TODO: logout in API?
      }
    },

    closeAlert () {
      if (this.alert.cb) this.alert.cb()
      this.$store.commit('setAlert', null)
    }
  },

  created () {
    this.$store.commit('setProcessing', false)
    this.$store.commit('setFullscreenMode', false)
    this.$store.commit('setExportMode', false)
    this.$store.commit('setJuryMode', false)
    this.$store.commit('setPressMode', false)
  },

  destroyed () {
    this.$store.commit('setAlert', null)
  },
}
</script>

<style lang="less">
html, body, #app {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: @font-size;
  line-height: @line-h;
  color: @font-color;
  background-color: #ffffff;
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;
}

#app {
  > .content {
    max-width: 1000px;
    min-height: calc(100vh - 185px - 160px); // - header + footer
    margin: 0 auto @spacing-v;
    position: relative;
    padding: 10px;

    &.fullscreen {
      max-width: 1180px;
    }
  }
}

.byak-btn {
  display: block;
  box-sizing: content-box;
  height: @btn-h;
  padding: 2px 5px 2px 5px;
  color: @font-color;
  text-decoration: none;
  line-height: 1.5rem;
  cursor: pointer;
  background-color: @primary;
  border-bottom: 1px #452D00 solid;
  border-right: 1px #452D00 solid;
  border-left: 1px #ECE9E2 solid;
  border-top: 1px #ECE9E2 solid;

  &.icon {
    display: inline-block;
    box-sizing: border-box;
    width: @btn-h;
    line-height: @btn-h;
    font-weight: 900;
    padding: 0;
    text-align: center;
    margin-right: 5px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.inline {
    display: inline-block;
    width: auto;
    min-width: 14px;
    text-align: center;
    margin-left: 20px;
    font-weight: 700;
  }

  &.inactive {
    background-color: @secondary;
    border-bottom: 1px darken(@secondary, 16%) solid;
    border-right: 1px darken(@secondary, 16%) solid;
    border-left: 1px lighten(@secondary, 12%) solid;
    border-top: 1px lighten(@secondary, 12%) solid;
  }
}
.tooltip {
  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

button[type=submit] {
  margin-bottom: @spacing-v*4;
}

button {
  &.byak-btn {
    width: calc(100% - @spacing-h);
    text-align: left;
  }

  &.small {
    width: auto;
    display: inline-block;
  }

  &.right {
    float: right;
  }
}

h2 {
  margin-top: 20px;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.6rem;
  color: lighten(@font-color, 20%);
}

p {
  line-height: 1.1rem;
}

.content {
  text-align: left;
}

form {
  .form-row {
    position: relative;
    margin-bottom: @gutter;
    clear: both;
  }
}

.group {
  .group-subtitle,
  .form-field h4 {
    display: block;
    clear: both;
    padding: 20px 0 4px;
    // margin-left: -10px;
    // text-indent: -100%;
    @media all and (min-width: 1000px) {
      max-width: 270px;
      text-align: right;
      margin: 0;
      margin-left: -280px;
    }
  }
}

.embed-many {
  margin-top: 0px;
  padding-bottom: @spacing-v;
  border-bottom: 1px solid @primary;

  > span {
    padding: 8px 0 0;
    display: inline-block;
  }

  &.solo, .embed-many {
    border-color: @secondary;
  }

  .group-subtitle {
    padding-top: @spacing-v;
  }
}

.layout-main {
  line-height: 1.5;
}

.layout-right {
  margin-top: 10px;

  strong {
    color: @font-color;
  }

  .group-subtitle {
    text-indent: initial;
    text-align: left;
    margin-left: 0;
  }

  .is-child {
    padding-left: @spacing-h * 5;

    .group-subtitle {
      padding-top: 0;
    }

    .text-counter {
      top: 3px;
    }
  }
}

.pos-left {
  float: left;
  min-height: @font-size;
}

.pos-right {
  float: right;
  min-height: @font-size;
}

.offset-right {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: -314px;
}

.relative {
  position: relative;
}

.alert {
  position: fixed;
  top: 40%;
  left: 10%;
  width: 80%;
  background: white;
  padding: 20px;
  z-index: 999;
  text-align: left;
  border: 1px solid @primary;
  box-shadow: 2px 2px 10px 2000px rgba(0, 0, 0, 0.33);
  box-sizing: border-box;

  > div > div {
    max-height: 6rem;
    overflow-y: auto;
  }
}

.part-tabs {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
  li {
    display: inline-block;
    padding: 0 10px @gutter 0;
  }
}

.processing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 888;

  > .unicon {
    top: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.start-img {
  img {
    width: 96%;
  }
}

.right-rotated {
  position: absolute;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: left center;
  left: -12px;
  top: 23px;
  // top: -84px;
  height: 20px;
  font-size: 1em;
  margin: 0;
  padding: 0;
  letter-spacing: -0.1px;
}

@media all and (min-width: 1000px) {
  // Desktop
  .start {
    position: relative;
    min-height: 460px;
  }

  .start-main-full {
    margin-left: 240px;
  }

  .start-main-full p {
    font-size: 15px;
  }

  .start-container {
    position: relative;
  }

  .start-main {
    margin-left: 240px;
    width: 400px;
  }

  .start-img {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 360px;
    padding-top: 0px;

    p {
      span {
        font-size: smaller;
      }
    }

    img {
      min-width: 100%;
      min-height: 240px;
      background-color: #eee;
    }
  }

  .layout {
    position: relative;

    .layout-main {
      margin-left: 240px;
      width: 420px;

      h1 {
        margin-top: 20px;
        font-size: 2.5rem;
        line-height: 2.5rem;
        color: lighten(@font-color, 40%);
      }

      label:not(.labelCheckbox):not(.labelCheckboxSubs) > span:first-child:not(.inline-label),
      label:not(.labelCheckbox):not(.labelCheckboxSubs).conditionalOptions div>span:first-child:not(.inline-label),
      label.label-left > span:first-child:not(.inline-label) {
        float: left;
        width: 250px;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: -260px;
        padding-left: 0;
        line-height: 24px;
        height: 24px;

        &.multi-line {
          white-space: pre-line;
          height: auto;
          text-overflow: initial;
          overflow: visible;
          line-height: 18px;
        }
      }

      &.fullw {
        width: auto;
      }
    }

    .layout-right {
      position: absolute;
      right: 0;
      top: 0;
      width: 260px;
      margin-top: 0;

      &.fullw {
        width: auto;
      }
    }

    .layout-bottom {
      margin-top: 60px;
      margin-left: 240px;
      width: 420px;

      &.fullw {
        width: auto;
      }
    }

    h3 {
      margin: 1.5em 0 0.5em;
    }
  }

  .alert {
    top: 50%;
    left: 25%;
    width: 50%;
    margin-top: -60px;
  }

  .jury-ui, .press-ui, .export-ui {
    .layout-main.fullw {
      margin-left: 10px;
      width: auto;
      max-width: 740px;
    }
    .layout-right {
      width: auto;
      max-width: 260px;
      min-width: 165px;
    }
  }
}

.hidden {
  opacity: 0;
  width: 0;
  height: 0;
  visibility: hidden;
}

.inline {
  display: inline;
}

.ib {
  display: inline-block !important;
}

.if {
  display: inline-flex !important;
}

.flex-spacer {
  margin-left: auto !important;
}

button.icon:not(.color) {
  background: none;
  border: none;
  cursor: pointer;
}

.clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .group-subtitle {
    width: 100%;
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

hr {
  border: 1px solid @primary;
  border-top: 0;
}

.unicon {
  vertical-align: middle;
}

div {
  box-sizing: content-box;
}

.byak-infotext,
.layout-right p {
  color: #5A5A5A;
  font-size: 0.8rem;
}

.text-small {
  font-size: 0.75rem;
}

.is-bold {
  label, strong {
    font-weight: bold !important;
  }
}

.is-thin {
  label, strong, h4 {
    font-weight: normal !important;
  }
}

.form-col, .field-col {
  display: inline-block;
}

.field-row {
  float: left;
  width: 0%;

  .group-subtitle {
    padding-top: 0;
  }
}

.form-field {
  display: initial;
  width: 100%;

  label {
    position: relative;
    display: block;
  }
}

.pull-left {
  margin-left: -270px;
  margin-top: -15px;
  width: 260px;
  position: absolute !important;

  span {
    float: right;
    padding-right: 3px;
  }
}

.label-unit {
  position: absolute;
  left: 100%;
  color: #666;
  width: max-content;
  padding-left: @spacing-h;
  height:  @input-h;
  line-height:  @input-h;
  display: inline-flex;
  font-size: 0.75rem;
}

.single-row {
  display: block;
  float: none;
  width: 100%;
  overflow: visible;
}

.blockmodel {
  display: block;
}

.underline {
  text-decoration: underline;
}

.passive {
  opacity: 0.9;
  pointer-events: none;
}

.invisible {
  opacity: 0;
}

.clickable {
  cursor: pointer;
}

.posrel {
  position: relative;
}

.error {
  input, select, .inputTextarea, .labelRadio, .labelCheckbox::before {
    background-color: rgba(255, 0, 0, 0.2);
    // border: 1px solid red;
  }
  label:not(.labelRadio):not(.labelCheckbox):not(.labelCheckboxSubs), .form-row .group-subtitle,
  > span, .group-subtitle, span.error, .text-counter .minLength, .text-counter .maxLength {
    color: red;
  }
}

// jury mode
.jury-ui {
  @jury-bg: #ddd;
  @jury-input: darken(@jury-bg, 10%);
  @jury-font: #666;
  @jury-primary: #333;

  background-color: @jury-bg;

  > h2, #header #nav a.router-link-exact-active {
    color: @primary;
  }
  .preview-box {
    background-color: darken(@jury-bg, 4%);
  }

  .inputText, .inputDatetime, .inputWysiwyg, .inputTextarea, select, .labelRadio, .labelCheckbox:before,
  .labelCheckboxSubs:before, .byak-link.inactive a::before, div[contenteditable] {
    background-color: @jury-input;
    border-color: @jury-bg;
  }
}

// export mode
.export-ui {
  @export-bg: #fff;
  @export-primary: #fc3;

  background-color: @export-bg;

  > h2, #header #nav a.router-link-exact-active {
    color: @export-primary;
  }

  .preview-box {
    background-color: darken(@export-bg, 4%);

    &.active, &:hover {
      border-color: @export-primary;
    }
  }

  #nav .unicon svg {
    fill: @export-primary !important;
  }

  .byak-btn, .byak-link a::before, .byak-cube-grid .byak-cube {
    color: @font-color;
    background-color: @export-primary;
  }
}

// press mode
.press-ui {
  @press-bg: #fff;
  // @press-input: lighten(@press-bg, 15%);
  @press-font: #333;
  @press-primary: #0099d3;

  background-color: @press-bg;
  color: @press-font;

  > h2, #header #nav a.router-link-exact-active {
    color: @press-primary;
  }

  .preview-box {
    background-color: darken(@press-bg, 4%);

    &.active, &:hover {
      border-color: @press-primary;
    }
  }

  *:not(.byak-link) > .unicon svg {
    fill: @press-primary !important;
  }

  .byak-btn, .byak-link a::before, .byak-cube-grid .byak-cube {
    color: @font-color;
    background-color: @press-primary;
  }

  // .inputText, .inputDatetime, .inputWysiwyg, .inputTextarea, select, .labelRadio, .labelCheckbox:before,
  // .labelCheckboxSubs:before, .byak-link.inactive a::before, div[contenteditable] {
  //   background-color: @press-input;
  //   border-color: @press-bg;
  // }
}

// dark mode
.dark-ui {
  @dark-bg: rgba(31, 41, 55, 255);
  @dark-input: rgba(64, 77, 96, 255);
  @dark-font: #f3f3f3;

  background-color: @dark-bg;
  color: @dark-font;

  a, .byak-infotext, .byak-link a, #header #nav a, h1, h2, h3, h4, h5 {
    color: @dark-font;
  }

  .text-counter span {
    color: @dark-font;
  }

  #header h2, .group-subtitle {
    color: @primary;
  }

  .inputText, .inputDatetime, .inputWysiwyg, .inputTextarea, select, .labelRadio, .labelCheckbox:before,
  .labelCheckboxSubs:before, .byak-link.inactive a::before, div[contenteditable] {
    background-color: @dark-input;
    border-color: @dark-bg;
    color: @dark-font;

  }

  .inputText:read-only {
    background-color: darken(@dark-input, 8%);
  }

  .alert {
    background: @dark-input;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  .layout-full {
    margin-left: 120px;
    width: initial;
  }

  &#app > .content {
    min-height: calc(100vh - 145px - 160px); // - header + footer
  }
}

.v-popper__wrapper {
  max-width: 420px;
}

@media all and (min-width: 1000px) {
  .dark-ui {
    .layout-main, .layout-bottom {
      margin-left: 120px;
      width: 590px;
    }
  }
}

// some sample transitions from vuejs.org docs
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all .4s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}
.bounce-enter-active {
  animation: bounce-in .6s;
}
.bounce-leave-active {
  animation: bounce-in .4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.fade-down-enter-active {
  animation: fade-down .6s;
}
.fade-down-leave-active {
  animation: fade-down .4s reverse;
}
@keyframes fade-down {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 100vh;
    opacity: 1;
  }
}
@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

// generate helpers
each(range(20),{
  .mt-@{value} { margin-top: @value * 4px !important; }
  .mr-@{value} { margin-right: @value * 4px !important; }
  .mb-@{value} { margin-bottom: @value * 4px !important; }
  .ml-@{value} { margin-left: @value * 4px !important; }
  .mx-@{value} { margin-left: @value * 4px !important; margin-right: @value * 4px !important; }
  .my-@{value} { margin-top: @value * 4px !important; margin-bottom: @value * 4px !important; }
  .m-@{value}  { margin: @value * 4px !important; }
  .pt-@{value} { padding-top: @value * 4px !important; }
  .pr-@{value} { padding-right: @value * 4px !important; }
  .pb-@{value} { padding-bottom: @value * 4px !important; }
  .pl-@{value} { padding-left: @value * 4px !important; }
  .px-@{value} { padding-left: @value * 4px !important; padding-right: @value * 4px !important; }
  .py-@{value} { padding-top: @value * 4px !important; padding-bottom: @value * 4px !important; }
  .p-@{value}  { padding: @value * 4px !important; }
  .spacer.col-@{value} { height: @value * @line-h; }
});

each(range(100),{
  .col-@{value} { width: @value * 1% !important; }
});
</style>
