
import Vue from 'vue'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import { 
  HardBreak, 
  Heading, 
  ListItem, 
  // OrderedList, 
  BulletList, 
  Bold, 
  Italic, 
  Link, 
  Underline, 
} from 'tiptap-extensions'


export default Vue.extend({
  name: 'FormWysiwyg',

  components: {
    EditorContent,
    EditorMenuBubble,
  },

  data: () => {
    return {
      editor: null as any,
    }
  },

  props: [
    'name',
    'label',
    'value',
    'type',
    'validation',
  ],

  methods: {
    setLink() {
      let url = prompt("Bitte geben sie die URL ein:");

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        return this.editor.commands.link({ href: null })
      }

      // invalid
      if (!this.validateUrl(url)) {
        return alert('Die URL scheint nicht valide zu sein.')
      }

      // update link
      this.editor.commands.link({ href: url })
    },

    updateInput(val: any) {
      this.$emit('input', val)
    },

    validateUrl(url: string) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(),
        // new OrderedList(),
        new BulletList(),
        new Bold(),
        new Italic(),
        new Link(),
        new Underline(),
        // new History(),
      ],
      onUpdate: (editor: any) => {
        let html: string = editor.getHTML()
        if (html.includes('<a href=')) {
          var re = new RegExp('<a href=', 'g')
          html = html.replace(re, '<a target="_blank" href=')
        }
        this.updateInput(html)
      },
    })
  },

  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy()
    }
  },

})
