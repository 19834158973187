/* tslint:disable:no-console */
import Vue from 'vue';

export const userModule: any = {
  state: {
    currentUser: null,
    userCount: 0,
    users: [],
    usernames: new Map(),
  },
  mutations: {
    setUsers(state: any, data: any) {
      state.users = data.items;
      state.userCount = data.count;
      state.usernames = new Map(data.items.map((u: any) => [u.id, u.firstname + ' ' + u.lastname]));
    },
    setUser(state: any, user: any) {
      state.currentUser = user;
    },
  },
  actions: {
    /**
     * adds a user
     * @param context
     * @param data
     */
    async addUser(context: any, data: any) {
      console.log('addUser: ', data);
      let URL = context.rootState.baseUrl + 'crud/user'
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-AUTH-TOKEN': context.rootState.user.apiToken
        },
        body: JSON.stringify(data),
      })
      const user = await response.json()
      if (response.status === 401) {
        context.dispatch('userNotAuthenticated', response)
        throw new Error('user not authenticated')
      } else if (response.status >= 400 || !user) {
        if (user) {
          throw new Error(JSON.stringify(user))
        } else {
          throw new Error('error creating user')
        }
      } else {
        return user
      }
    },

    /**
     * saves an edited user
     * @param context
     * @param data
     */
    async saveUser(context: any, data: any) {
      const { id, formData } = data
      console.log('saveUser -> id, formData: ', id, formData);
      let URL = context.rootState.baseUrl + 'crud/user/' + id
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-AUTH-TOKEN': context.rootState.user.apiToken
        },
        body: JSON.stringify(formData),
      })
      const user = await response.json()
      if (response.status === 401) {
        context.dispatch('userNotAuthenticated', response)
        throw new Error('user not authenticated')
      } else if (response.status >= 400 || !user) {
        if (user) {
          throw new Error(JSON.stringify(user))
        } else {
          throw new Error('error saving user')
        }
      }
    },

    /**
     * fetches a user from cache
     * @param context
     * @param id
     */
    fetchUser(context: any, id: any) {
      if (context.state.users.length < 1) {
        context.dispatch('getUsers')
      }
      return context.state.users.filter((u: any) => u.id == id)[0]
    },

    /**
     * fetches a user
     * @param context
     * @param id
     */
    async getUser(context: any, id: any) {
      try {
        let URL = context.rootState.baseUrl + 'crud/user/' + id
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const user = await response.json()
        // console.log('user: ', user);
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !user) {
          throw new Error('error fetching user')
        } else {
          return user
        }
      } catch (error) {
        console.error('getUser error -> ', (error as any).message)
      }
    },

    /**
     * fetches all users
     * @param context
     * @param params
     */
    async getUsers(context: any, params: any = '') {
      try {
        let URL = context.rootState.baseUrl + 'crud/user/list' + params
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const users = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !users) {
          throw new Error('error fetching users')
        } else {
          context.commit('setUsers', users)
          return users
        }
      } catch (error) {
        console.error('getUsers error -> ', (error as any).message)
      }
    },

    /**
     * permanently deletes a user from database
     * @param context
     * @param id
     */
    async deleteUser(context: any, id: any) {
      try {
        let URL = context.rootState.baseUrl + 'crud/user/' + id
        const response = await fetch(URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const user = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400) {
          throw new Error('error deleting user')
        } else {
          return user
        }
      } catch (error) {
        console.error('deleteUser error -> ', (error as any).message)
      }
    },
  },
};
