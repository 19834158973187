
import Vue from 'vue'

export default Vue.extend({
  name: 'FormFile',

  data: () => {
    return {
      err: '',
    };
  },

  props: [
    'errors',
    'file',
    'label',
    'name',
    'type',
    'validation',
    'value',
  ],

  computed: {
    darkMode (): any {
      return this.$store.state.darkMode;
    },
    fill (): any {
      return this.darkMode ? '#FFA600' : '#333'
    },
    imgUrl () {
      return this.$store.state.baseUrl
    },
  },

  methods: {
    updateInput(val: any) {
      this.$emit('input', val)
    },
    removeFile(e: Event) {
      this.$emit('remove', e, this.name)
      this.$emit('input', '')
    },
    addFile(e: Event) {
      this.$emit('save', e, this.name)
    },
  }
})
