/* tslint:disable:no-console */
import Vue from 'vue';

export const awardModule: any = {
  state: {
    currentAward: null,
    awardCount: 0,
    awards: [],
    allAwards: [],
  },
  mutations: {
    setAwards(state: any, data: any) {
      state.awards = data.items;
      state.awardCount = data.count;
    },
    setAllAwards(state: any, data: any) {
      state.allAwards = data.items;
    },
    setAward(state: any, award: any) {
      state.currentAward = award;
    },
  },
  actions: {
    /**
     * adds a award
     * @param context
     * @param data
     */
    async addAward(context: any, data: any) {
      console.log('addAward: ', data);
      try {
        let URL = context.rootState.baseUrl + 'crud/award'
        const response = await fetch(URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
          body: JSON.stringify(data),
        })
        const award = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !award) {
          throw new Error('error creating award')
        } else {
          return award
        }
      } catch (error) {
        console.error('addAward error -> ', (error as any).message)
      }
    },

    /**
     * saves an edited award
     * @param context
     * @param data
     */
    async saveAward(context: any, data: any) {
      const { id, formData } = data
      console.log('saveAward -> id, formData: ', id, formData);
      let URL = context.rootState.baseUrl + 'crud/award/' + id
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-AUTH-TOKEN': context.rootState.user.apiToken
        },
        body: JSON.stringify(formData),
      })
      const award = await response.json()
      if (response.status === 401) {
        context.dispatch('userNotAuthenticated', response)
        throw new Error('user not authenticated')
      } else if (response.status >= 400 || !award) {
        if (award) {
          throw new Error(JSON.stringify(award))
        } else {
          throw new Error('error saving award')
        }
      } else {
        context.commit('setAward', award)
      }
    },

    /**
     * fetches a award from cache
     * @param context
     * @param id
     */
    fetchAward(context: any, id: any) {
      if (context.state.awards.length < 1) {
        context.dispatch('getAwards')
      }
      return context.state.awards.filter((u: any) => u.id == id)[0]
    },

    /**
     * fetches a award
     * @param context
     * @param id
     */
    async getAward(context: any, id: any) {
      try {
        let URL = context.rootState.baseUrl + 'crud/award/' + id
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const award = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !award) {
          throw new Error('error fetching award')
        } else {
          // context.commit('setAward', award)
          return award
        }
      } catch (error) {
        console.error('getAward error -> ', (error as any).message)
      }
    },

    /**
     * fetches all awards
     * @param context
     * @param query
     */
    async getAwards(context: any, query: string = '') {
      try {
        let URL = context.rootState.baseUrl + 'crud/award/list' + query
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const awards = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !awards) {
          throw new Error('error fetching awards')
        } else {
          context.commit('setAwards', awards)
          return awards
        }
      } catch (error) {
        console.error('getAwards error -> ', (error as any).message)
      }
    },

    /**
     * fetches all awards
     * @param context
     * @param query
     */
    async getAllAwards(context: any, query: string = '') {
      try {
        let URL = context.rootState.baseUrl + 'crud/award/list-all'
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const awards = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !awards) {
          throw new Error('error fetching awards')
        } else {
          context.commit('setAllAwards', awards)
          return awards
        }
      } catch (error) {
        console.error('getAwards error -> ', (error as any).message)
      }
    },

    /**
     * permanently deletes a award from database
     * @param context
     * @param id
     */
    async deleteAward(context: any, id: any) {
      try {
        let URL = context.rootState.baseUrl + 'crud/award/' + id
        const response = await fetch(URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const award = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400) {
          throw new Error('error deleting award')
        } else {
          return award
        }
      } catch (error) {
        console.error('deleteAward error -> ', (error as any).message)
      }
    },
  },
};
