
import Vue from 'vue'

export default Vue.extend({
  name: 'TextCounter',

  props: [
    'value',
    'validation',
  ],
})
