
import Vue from 'vue'

export default Vue.extend({
  name: 'FormDatetime',

  data: () => {
    return {
    }
  },

  props: [
    'errors',
    'label',
    'name',
    'placeholder',
    'type',
    'validation',
    'value',
  ],

  methods: {
    checkErrors(errors: any, value: string) {
      if (errors && errors.length > 0) {
        let e = errors.filter((err: any) => {
          if (err.field) {
            // console.log('err.field: ', err.field);
            if (this.name === err.field) {
              return true
            } else {
              const regex = err.field.match(/{([^}]+)}/) 
              if (regex && regex[1] && regex[1].split(':')[1] === value) return true
            }
          } 
          return false
        })
        if (e.length > 0) return 'error'
      }
    },
    getMin() {
      const date = new Date()
      return (date.getUTCFullYear() - 2) + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate() + 'T00:00'
    },
    getMax() {
      const date = new Date()
      return (date.getUTCFullYear() + 3)+ '-12-31T00:00'
    },
    getValue() {
      if (!this.value) return ''
      const res = this.value.split(':').slice(0,2).join(':')
      return res
    },
    matchValidation(o: any, validation: any) {
      if (!validation.validationsIfConditionMatch) return true
      const res = validation.validationsIfConditionMatch.filter((v: string) => v === o.value);
      return res.length > 0
    },
    updateInput(val: any) {
      // console.log('val: ', val);
      this.$emit('input', val)
    },
  },
})
