import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGoodTablePlugin from 'vue-good-table'
import VTooltip from 'v-tooltip'


// import the good-table styles
import 'vue-good-table/dist/vue-good-table.css'
import 'v-tooltip/dist/v-tooltip.css'

Vue.use(VueGoodTablePlugin)
VTooltip.options.themes.tooltip.placement = 'left'
Vue.use(VTooltip)

// init plugins
import './plugins/directives'
import './plugins/icons'
import './plugins/mq'

Vue.config.productionTip = false
Vue.mixin({
  methods: {
    debug: val => {
      if (typeof val === 'object') {
        val = JSON.parse(JSON.stringify(val))
      }
      console.log(val)
    },
  }
})

// add global components
const requireFormComponents = require.context('./components/forms', false, /[\w-]+\.vue$/)
requireFormComponents.keys().forEach((fileName) => {
  const componentConfig = requireFormComponents(fileName)
  const componentName = 'form-' + fileName.replace('./', '').replace(/\.\w+$/, '')
  Vue.component(componentName, componentConfig.default || componentConfig)
})
const requireUiComponents = require.context('./components/ui', false, /[\w-]+\.vue$/)
requireUiComponents.keys().forEach((fileName) => {
  const componentConfig = requireUiComponents(fileName)
  const componentName = 'ui-' + fileName.replace('./', '').replace(/\.\w+$/, '')
  Vue.component(componentName, componentConfig.default || componentConfig)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
