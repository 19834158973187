
import Vue from 'vue'

export default Vue.extend({
  name: 'Info',

  data: () => {
    return {
      show: true,
    };
  },

  props: [
    'type',
    'msg',
  ],

  methods: {
    close() {
      this.show = false;
    }
  },
})
