
import Vue from 'vue'

export default Vue.extend({
  name: 'ByakLink',

  props: [
    'hasIcon',
    'icon',
    'inactive',
    'isStrong',
    'isTab',
    'to',
    'target',
    'text',
    'hideButton'
  ],
})
