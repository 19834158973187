/* tslint:disable:no-console */
import Vue from 'vue';

export const projectModule: any = {
  state: {
    projectCount: 0,
    projects: [],
  },
  mutations: {
    setProjects(state: any, data: any) {
      state.projects = data.items;
      state.projectCount = data.count;
    },
    setProject(state: any, project: any) {
      state.currentProject = project;
    },
  },
  actions: {

    /**
     * saves an edited project
     * @param context
     * @param data
     */
    async saveProjectCrud(context: any, data: any) {
      const { awardType, year, formName, id, part, formData } = data
      // console.log('saveProjectCrud -> id, data: ', id, data);
      let URL = context.rootState.baseUrl + `crud/projekt/${awardType}/${year}/${formName}/${part}/${id}`
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-AUTH-TOKEN': context.rootState.user.apiToken
        },
        body: JSON.stringify(formData),
      })
      const project = await response.json()
      if (response.status === 401) {
        context.dispatch('userNotAuthenticated', response)
        throw new Error('user not authenticated')
      } else if (response.status >= 400 || !project) {
        if (project) {
          throw new Error(JSON.stringify(project))
        } else {
          throw new Error('error saving project')
        }
      } else {
        context.commit('setProject', project)
      }
    },

    /**
     * fetches a project from cache
     * @param context
     * @param id
     */
    fetchProject(context: any, id: any) {
      if (context.state.projects.length < 1) {
        context.dispatch('getProjects')
      }
      return context.state.projects.filter((u: any) => u.id == id)[0]
    },

    /**
     * fetches a project
     * @param context
     * @param id
     */
    async getProject(context: any, id: any) {
      try {
        let URL = context.rootState.baseUrl + 'crud/projekt/' + id
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const project = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !project) {
          throw new Error('error fetching project')
        } else {
          // context.commit('setProject', project)
          return project
        }
      } catch (error) {
        console.error('getProject error -> ', (error as any).message)
      }
    },

    /**
     * fetches all projects
     * @param context
     * @param query
     */
    async getProjects(context: any, query: string = '') {
      try {
        let URL = context.rootState.baseUrl + 'crud/projekt/list' + query
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const projects = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !projects) {
          throw new Error('error fetching projects')
        } else {
          context.commit('setProjects', projects)
          return projects
        }
      } catch (error) {
        console.error('getProjects error -> ', (error as any).message)
      }
    },

    /**
     * permanently deletes a project from database
     * @param context
     * @param id
     */
    async deleteProjectMain(context: any, id: any) {
      try {
        let URL = context.rootState.baseUrl + 'crud/projekt/' + id
        const response = await fetch(URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const project = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400) {
          throw new Error('error deleting project')
        } else {
          return project
        }
      } catch (error) {
        console.error('deleteProject error -> ', (error as any).message)
      }
    },

    /**
     * permanently deletes a project detail from database
     * @param context
     * @param options
     */
    async deleteProjectDetail(context: any, options: any) {
      try {
        const { awardType, year, id } = options
        let URL = context.rootState.baseUrl + `projekts/${awardType}/${year}/${id}`
        const response = await fetch(URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const projectDetail = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400) {
          throw new Error('error deleting projectDetail')
        } else {
          return projectDetail
        }
      } catch (error) {
        console.error('deleteProjectDetail error -> ', (error as any).message)
      }
    },
  },
};
