
import Vue from 'vue'

export default Vue.extend({
  name: 'Pagination',

  props: [
    'count',
    'goto',
    'limit',
    'page',
    'total',
  ],

  computed: {
    currentPage () {
      return parseInt(this.page)
    },
    pages () {
      return Math.ceil(this.total / this.limit)
    },
  }
})
