import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    sm: 420,
    md: 1024,
    lg: Infinity,
  }
})
