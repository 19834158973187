import Vue from 'vue'
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import {
  uniAngleRight, uniAngleLeft, uniAngleDown, uniAngleUp, uniBars, uniBold, uniCheck, uniCheckCircle, uniCircle,
  uniCommentInfo, uniEnvelope, uniExclamationTriangle, uniExternalLinkAlt, uniEye, uniFileAlt, uniFileInfoAlt, uniFileCheckAlt, uniFileMinusAlt,
  uniFileUploadAlt, uniFileQuestionAlt, uniHospitalSquareSign, uniImage, uniImageMinus, uniImagePlus, uniImageRedo,
  uniImageUpload, uniInfoCircle, uniItalic, uniLink, uniListUl, uniMessage, uniMinus, uniMinusCircle, uniMultiply, uniParagraph,
  uniPen, uniPlus, uniProcess, uniSave, uniSearch, uniSignin, uniSignout, uniSquareFull, uniTimes, uniTimesCircle,
  uniTrash, uniTrashAlt, uniUser, uniUserSquare, uniApps, uniAlignJustify, uniArrowFromTop, uniTopArrowToTop, uniQuestion
} from 'vue-unicons/dist/icons'

Unicon.add([
  uniAngleRight, uniAngleLeft, uniAngleDown, uniAngleUp, uniBars, uniBold, uniCheck, uniCheckCircle, uniCircle,
  uniCommentInfo, uniEnvelope, uniExclamationTriangle, uniExternalLinkAlt, uniEye, uniFileAlt, uniFileInfoAlt, uniFileCheckAlt, uniFileMinusAlt,
  uniFileUploadAlt, uniFileQuestionAlt, uniHospitalSquareSign, uniImage, uniImageMinus, uniImagePlus, uniImageRedo,
  uniImageUpload, uniInfoCircle, uniItalic, uniLink, uniListUl, uniMessage, uniMinus, uniMinusCircle, uniMultiply, uniParagraph,
  uniPen, uniPlus, uniProcess, uniSave, uniSearch, uniSignin, uniSignout, uniSquareFull, uniTimes, uniTimesCircle,
  uniTrash, uniTrashAlt, uniUser, uniUserSquare, uniApps, uniAlignJustify, uniArrowFromTop, uniTopArrowToTop, uniQuestion
])

Vue.use(Unicon, {
  fill: '#FFA600',
  height: 24,
  width: 24
})
