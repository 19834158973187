
import Vue from 'vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default Vue.extend({
  name: 'FormImage',

  data: () => {
    return {
      preview: null,
      cropperCanTriggerSave: false
    };
  },

  props: [
    'errors',
    'field',
    'image',
    'label',
    'name',
    'userCrop',
    'validation',
    'value',
  ],

  computed: {
    aspectRatio () {
      let res: any = 4/3;
      if (this.userCrop) {
        let asp = this.userCrop.aspectRatio.split(':')
        res = parseInt(asp[0]) / parseInt(asp[1])
      }
      return res
    },
    darkMode (): any {
      return this.$store.state.darkMode;
    },
    fill (): any {
      return this.$store.state.darkMode ? '#FFA600' : '#333'
    },
    imgUrl () {
      return this.$store.state.baseUrl
    },
  },

  methods: {
    addImage (e: Event) {
      console.log('addImage')
      let target = (e.target as HTMLInputElement)
      console.log('addImage ', target)
      if (target.files !== null && target.files.length > 0) {
        this.$emit('save', e, this.name)
      } else {
        this.$emit('input', '')
      }
    },

    checkErrors(name: string) {
      if (this.errors && this.errors.length > 0) {
        let e = this.errors.filter((err: any) => {
          if (err.field && err.field.includes(name) && err.field.includes('userCrop')) {
            return true
          }
          return false
        })
        if (e.length > 0) return 'error'
      }
    },

    crop () {
      const cropper: any = this.$refs.cropper
      const { coordinates, canvas, } = cropper.getResult()
      this.preview = canvas.toDataURL()
      // this.$emit('crop', coordinates)
      this.$emit('crop', {
        width: coordinates.width,
        height: coordinates.height,
        left: coordinates.left,
        top: coordinates.top
      })

      if (this.cropperCanTriggerSave) {
        console.log('will triggerSaveForm')
        // this.$store.commit('triggerSaveForm', true)
        this.cropperCanTriggerSave = false
      }
    },

    ready () {
      setTimeout(() => {
        this.cropperCanTriggerSave = true
      }, 500);
    },

    refreshPreview() {
      this.$store.commit('triggerSaveForm', true)
    },

    sizeRestriction() {
      const limits = {
        minWidth: this.validation.minWidth,
        minHeight: this.validation.minHeight,
        maxWidth: Infinity,
        maxHeight: Infinity,
      }
      return limits;
    },

    removeImage (e: Event) {
      this.$emit('remove', e, this.name)
      this.$emit('input', '')
      this.preview = null

      const filePicker: any = this.$refs.filePicker
      filePicker.value = null
    },

    reset () {
      const cropper: any = this.$refs.cropper
      cropper.reset()
      setTimeout(() => {
        const { canvas, } = cropper.getResult()
        this.preview = canvas.toDataURL()
      }, 200)
    },

    updateInput (val: any) {
      console.log('updateInput: ', val);
      this.$emit('input', val)
    },
  },

  components: {
    Cropper,
  },
})
