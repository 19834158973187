
import Vue from 'vue'

export default Vue.extend({
  name: 'Info',

  data: () => {
    return {
      show: true,
    };
  },

  props: [
    'dialog',
  ],

  methods: {
    close() {
      // this.show = false;
      this.$store.commit('setDialog', null)
    },
    callback() {
      if (this.dialog.callback) {
        this.dialog.callback()
        this.$store.commit('setDialog', null)
      }
    },
  },
})
