
import Vue from 'vue'

export default Vue.extend({
  name: 'FormInput',

  data: () => {
    const array: any[] = []
    const texts: any = {}
    const names: any = {}
    return {
      array,
      names,
      texts,
    }
  },

  props: [
    'errors',
    'field',
    'fields',
    'label',
    'name',
    'options',
    'placeholder',
    'type',
    'validation',
    'value',
  ],

  computed: {
    isDisabled () {
      // const opt: any = this.options
      // let dep: boolean = typeof this.dependency !== 'undefined'
      // if (dep && this.dependencyValue) {
      //   const key: string = (this.dependencyValue as string).toLowerCase();
      //   if (typeof opt[key] !== 'undefined') {
      //     dep = false
      //   }
      // }
      // return dep
      return false
    },
  },

  methods: {
    checkErrors(errors: any, value?: string, errorType: any = null) {
      let altClasses: any = false
      if (errors && errors.length > 0) {
        let e = errors.filter((err: any) => {
          if (err.field) {
            if (errorType && err.error === errorType) {
              altClasses = errorType
            } else if (errorType === 'minLength' || errorType === 'maxLength') {
              altClasses = 'none'
            }
            if (this.name === err.field || this.field && err.field.indexOf(this.field.name + '.') >= 0) {
              return true
            } else {
              const regex = err.field.match(/{([^}]+)}/)
              return (regex && regex[1] && regex[1].split(':')[1] === value)
            }
          }
          return false
        })
        if (e.length > 0) return altClasses || 'error'
      }
    },

    emitInput() {
      this.$emit('input', this.array)
    },

    emitSubfield(field: string, option: any, el: any) {
      this.array = this.array.map((o: any) => {
        if (o.value === option.value) {
          o.text = el.value
        }
        return o
      })
      this.emitInput()
    },

    matchValidation(o: any, validation: any) {
      if (!validation.validationsIfConditionMatch) return true
      const res = validation.validationsIfConditionMatch.filter((v: string) => v === o.value);
      return res.length > 0
    },

    updateInput(target: any) {
      // console.log('val: ', val);
      setTimeout(() => {
        this.$emit('input', target.value)
      }, 20)
    },
  },

  created () {
    if (this.value && this.value.length > 0) {
      this.array = this.value
    } else if (this.options) {
      // map options
      this.array = this.options.map((o: any) => {
        return {
          name: o.name,
          text: '',
          value: o.value,
        }
      })
    }
  },
})
