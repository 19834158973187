import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import VuexPersistence from 'vuex-persist'
import router from '../router'
import { awardModule } from './awards';
import { projectModule } from './projects';
import { sponsorModule } from './sponsors';
import { userModule } from './users';
import localForage from 'localforage';

Vue.use(Vuex)

const vuexLocal = new VuexPersistence<{}>({
  storage: localForage as any,
  asyncStorage: true,
})

const store: Store<any> = new Vuex.Store({
  state: {
    alert: null,
    allProjects: [],
    allProjectsCount: 0,
    appInfo: null,
    baseUrl: '/',
    crudListParams: {},
    currentAward: null,
    currentAwardConf: null,
    currentExportConf: null,
    currentJuryConf: null,
    currentProject: null,
    currentYear: null,
    defaultAdminFilter: null,
    darkMode: false,
    dialog: null,
    didSignup: false,
    infoFromLogin: false,
    exports: [],
    exportMode: false,
    fullscreenMode: false,
    juryGroups: [],
    juryMode: false,
    listAward: null,
    pressMode: false,
    processing: false,
    projectList: [],
    reloadTriggers: [],
    titles: { // titles of CRUD types
      awards: 'Kampagnen',
      users: 'Benutzer',
      projects: 'Projekte',
      sponsors: 'Sponsoren',
    },
    triggerSaveForm: false,
    user: null,
    userConf: null,
    userConfAward: null
  },
  mutations: {
    /**
     * Sets app info object
     * @param state
     * @param user
     */
    setAppInfo(state: any, info: any) {
      // console.log('setAppInfo -> info', info)
      state.appInfo = info
      state.userConf = info.user.userConfiguration
      if (state.appInfo.adminArea.projects.adminFilter) {
        state.defaultAdminFilter = state.appInfo.adminArea.projects.adminFilter
      }
      if (state.currentAward) {
        // refresh currentWard on fetch
        let refreshAward = state.appInfo.awards.activeAwards.filter((a: any) => a.id == state.currentAward.id)
        state.currentAward = refreshAward[0]
        let { awardType, year } = state.currentAward
        if (state.appInfo.awards.awardConfiguration[awardType]) {
          state.currentAwardConf = state.appInfo.awards.awardConfiguration[awardType][year]
        }
        if (state.appInfo.awards.juryConfiguration[awardType]) {
          state.currentJuryConf = state.appInfo.awards.juryConfiguration[awardType][year]
        }
        if (state.appInfo.awards.exportConfiguration[awardType]) {
          state.currentExportConf = state.appInfo.awards.exportConfiguration[awardType][year]
        }
        if (info.user.awardUserConfiguration[awardType]) {
          state.userConfAward = info.user.awardUserConfiguration[awardType][year]
        }
        else {
          state.userConfAward = null
        }
      }
    },

    /**
     * Sets CRUD list params
     * @param state
     * @param obj
     */
    setCrudListParams(state: any, obj: any) {
      state.crudListParams = obj
    },

    /**
     * Set if user just signed up
     * @param state
     * @param val
     */
    setDidSignup(state: any, val: boolean) {
      state.didSignup = val
    },

    /**
     * Set if user just signed up
     * @param state
     * @param val
     */
    setInfoFromLogin(state: any, val: boolean) {
      state.infoFromLogin = val
    },

    /**
     * Sets dark mode
     * @param state
     * @param val
     */
    setDarkMode(state: any, val: any) {
      state.darkMode = val
    },

    /**
     * Sets export mode
     * @param state
     * @param val
     */
    setExportMode(state: any, val: any) {
      state.exportMode = val
    },

    /**
     * Sets fullscreen mode
     * @param state
     * @param val
     */
    setFullscreenMode(state: any, val: any) {
      state.fullscreenMode = val
    },

    /**
     * Sets jury mode
     * @param state
     * @param val
     */
    setJuryMode(state: any, val: any) {
      state.juryMode = val
    },

    /**
     * Sets press mode
     * @param state
     * @param val
     */
    setPressMode(state: any, val: any) {
      state.pressMode = val
    },

    /**
     * Sets user's projects
     * @param state
     * @param user
     */
    setProjectList(state: any, projects: any) {
      state.projectList = projects
    },

    /**
     * Sets app processing state
     * @param state
     * @param processing
     */
    setProcessing(state: any, processing: boolean) {
      state.processing = processing
    },

    /**
     * Sets reloadTriggers
     * @param state
     * @param reloadTriggers
     */
    setReloadTriggers(state: any, reloadTriggers: any) {
      state.reloadTriggers = reloadTriggers
    },

    /**
     * Sets list of all projects
     * @param state
     * @param projects
     */
    setAllProjects(state: any, projects: any) {
      state.allProjects = projects
    },

    /**
     * Sets count of all projects
     * @param state
     * @param count
     */
    setAllProjectsCount(state: any, count: any) {
      state.allProjectsCount = count
    },

    /**
     * Sets export list
     * @param state
     * @param items
     */
    setExports(state: any, items: any) {
      state.exports = items
    },

    /**
     * Sets jury grouped projects
     * @param state
     * @param projects
     */
    setJuryGroups(state: any, projects: any) {
      state.juryGroups = projects
    },

    /**
     * Stores user
     * @param state
     * @param user
     */
    setUser(state: any, user: any) {
      state.user = user
    },

    /**
     * Stores selected award in CRUD list
     * @param state
     * @param award
     */
    setListAward(state: any, award: any) {
      state.listAward = award
    },

    /**
     * Stores current award
     * @param state
     * @param award
     */
    setCurrentAward(state: any, award: any) {
      if (!award) {
        state.currentAward = null
        state.currentAwardConf = null
      } else {
        let { awardType, year } = award
        state.currentAward = award
        if (state.appInfo.awards.awardConfiguration[awardType]) {
          state.currentAwardConf = state.appInfo.awards.awardConfiguration[awardType][year]
        }
        if (state.appInfo.awards.juryConfiguration[awardType]) {
          state.currentJuryConf = state.appInfo.awards.juryConfiguration[awardType][year]
        }
        if (state.appInfo.awards.exportConfiguration[awardType]) {
          state.currentExportConf = state.appInfo.awards.exportConfiguration[awardType][year]
        }

        if (state.appInfo.user.awardUserConfiguration[awardType]) {
          state.userConfAward = state.appInfo.user.awardUserConfiguration[awardType][year]
        }
        else {
          state.userConfAward = null
        }
      }
    },

    /**
     * Stores an alert to display
     * @param state
     * @param alert { type, message }
     */
    setAlert(state: any, alert: any) {
      if (!alert) {
        state.alert = null
      } else {
        state.alert = alert
      }
    },

    /**
     * Sets base URL for server requests
     * @param state
     * @param url
     */
    setBaseUrl(state: any, url: any) {
      state.baseUrl = url
    },

    /**
     * Stores dialog info
     * @param state
     * @param dialog { title, content, callback }
     */
    setDialog(state: any, dialog: any) {
      if (!dialog) {
        state.dialog = null
      } else {
        state.dialog = dialog
      }
    },

    /**
     * Triggers save
     * @param state
     * @param val { title, content, callback }
     */
    triggerSaveForm(state: any, val: any) {
      state.triggerSaveForm = val
      // console.log('SET triggerSaveForm: ', state.triggerSaveForm);
    },

    /**
     * Updates remote function value
     * @param state
     * @param data
     */
    updateRemoteFunctionValue(state: any, data: any) {
      console.log('updateRemoteFunctionValue: ', data);
    },
  },
  actions: {
    /**
     * Handles logout on 401
     * @param context
     * @param response
     */
    userNotAuthenticated(context: any, response: any) {
      console.log('userNotAuthenticated response: ', response);
      context.commit('setAlert', {
        type: 'error',
        message: 'Sie verfügen über keine gültige Authentifizierung, oder Ihre Anmeldung ist abglaufen.'
      })
      context.commit('setUser', null)
      router.push('/')
    },

    /**
     * Fetches app info object
     * @param context
     */
    async getAppInfo(context: any) {
      try {
        const headers: any = {
          'Content-Type': 'application/json'
        }
        if (context.state.user?.apiToken) {
          headers['X-AUTH-TOKEN'] = context.state.user.apiToken
        }
        const response = await fetch(context.state.baseUrl + 'info', {
          method: 'GET',
          headers,
        })
        const info = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !info) {
          throw new Error('error fetching info')
        } else {
          context.commit('setAppInfo', info)
        }
      } catch (error) {
        console.error('getAppInfo error -> ', (error as any).message)
      }
    },

    /**
     * Fetches current award's user projects
     * @param context
     * @param options
     */
    async getProjectList(context: any, options: any) {
      try {
        let URL = context.state.baseUrl + 'projekts'
        if (options) {
          const { awardType, year, phase } = options
          let str = ''
          if (phase === 'besichtigungstermineingabe') str = 'viewingappointment/'
          if (awardType && year) {
            URL = context.state.baseUrl + `projekts/${str}${awardType}/${year}`
          }
        }
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const projects = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !projects) {
          throw new Error('error fetching projects')
        } else {
          context.commit('setProjectList', projects)
        }
      } catch (error) {
        console.error('getProjectList error -> ', (error as any).message)
      }
    },

    /**
     * Fetches all user's projects
     * @param context
     * @param query
     */
    async getAllProjects(context: any, query: string = '') {
      try {
        let URL = context.state.baseUrl + 'projekts' + query
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const projects = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !projects) {
          throw new Error('error fetching projects')
        } else {
          context.commit('setAllProjects', projects)
        }
      } catch (error) {
        console.error('getAllProjects error -> ', (error as any).message)
      }
    },

    /**
     * Fetches a project
     * @param context
     * @param id
     */
    async getProjectItem(context: any, id: any) {
      try {
        const response = await fetch(context.state.baseUrl + `projekt/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const project = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !project) {
          throw new Error('error fetching project')
        } else {
          return project
        }
      } catch (error) {
        console.error('getProjectItem error -> ', (error as any).message)
      }
    },

    /**
     * Fetches a project for press
     * @param context
     * @param id
     */
    async getProjectPress(context: any, id: any) {
      try {
        const response = await fetch(context.state.baseUrl + `press/projekt/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const project = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !project) {
          throw new Error('error fetching project')
        } else {
          return project
        }
      } catch (error) {
        console.error('getProjectPress error -> ', (error as any).message)
      }
    },

    /**
     * Fetches a project for jury
     * @param context
     * @param id
     */
    async getProjectJury(context: any, data: any) {
      const { awardType, year, id } = data
      try {
        const response = await fetch(context.state.baseUrl + `jury/projekt/${awardType}/${year}/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const project = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !project) {
          throw new Error('error fetching project')
        } else {
          return project
        }
      } catch (error) {
        console.error('getProjectJury error -> ', (error as any).message)
      }
    },

    /**
     * Fetches exports
     * @param context
     * @param options
     */
    async getExports(context: any, options: any) {
      const { awardType, year, page } = options
      const p = page || 1
      let URL = context.state.baseUrl + `export/list/${awardType}/${year}/${p}`
      try {
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const exports = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !exports.items) {
          throw new Error('error fetching exports')
        } else {
          context.commit('setExports', exports.items)
          return exports
        }
      } catch (error) {
        console.error('getExports error -> ', (error as any).message)
      }
    },

    /**
     * Posts export action
     * @param context
     * @param options
     */
     async postExport(context: any, options: any) {
      const { awardType, year, ex, option } = options
      let URL = context.state.baseUrl + `export/start/${awardType}/${year}/${ex}/${option}`
      try {
        const response = await fetch(URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const exp = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !exp) {
          throw new Error('error posting export')
        } else {
          return exp
        }
      } catch (error) {
        console.error('postExport error -> ', (error as any).message)
      }
    },

    /**
     * Delete export action
     * @param context
     * @param options
     */
     async deleteExport(context: any, options: any) {
      const { id } = options
      let URL = context.state.baseUrl + `export/${id}`
      try {
        const response = await fetch(URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const res = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !res) {
          throw new Error('error deleting export')
        } else {
          return res
        }
      } catch (error) {
        console.error('deleteExport error -> ', (error as any).message)
      }
    },

    /**
     * Fetches projects for press
     * @param context
     * @param options
     */
    async getProjectsPress(context: any, options: any) {
      const { awardType, year, page, query } = options
      let URL = context.state.baseUrl + `press/projekts/${awardType}/${year}/${page}`
      if (query) URL += '?q=' + query
      try {
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const projects = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !projects.items) {
          throw new Error('error fetching project')
        } else {
          context.commit('setAllProjects', projects.items)
          context.commit('setAllProjectsCount', projects.count)
        }
      } catch (error) {
        console.error('getProjectsPress error -> ', (error as any).message)
      }
    },

    /**
     * Fetches projects for jury
     * @param context
     * @param options
     */
    async getProjectsJury(context: any, options: any) {
      // console.log('getProjectsJury options: ', options)
      const { awardType, year, groupBy, phase, query } = options
      let URL = context.state.baseUrl + `jury/projekts/${awardType}/${year}/${groupBy}/${phase}/1`
      if (query) URL += '?q=' + query
      try {
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const projects = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !projects) {
          throw new Error('error fetching projects')
        } else {
          context.commit('setJuryGroups', projects)
        }
      } catch (error) {
        console.error('getProjectsJury error -> ', (error as any).message)
      }
    },

    /**
     * Posts jury action
     * @param context
     * @param options
     */
    async postJuryAction(context: any, options: any) {
      console.log('postJuryAction options: ', options)
      const { awardType, year, action, phase, id } = options
      let URL = context.state.baseUrl + `jury/action/${awardType}/${year}/${phase}/${id}`
      try {
        const response = await fetch(URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
          body: JSON.stringify(action),
        })
        const result = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !result) {
          throw new Error('error posting action')
        } else {
          return result
        }
      } catch (error) {
        console.error('postJuryAction error -> ', (error as any).message)
      }
    },

    /**
     * Posts remote function call
     * @param context
     * @param options
     */
    async postRemoteFunction(context: any, options: any) {
      // console.log('postRemoteFunction options: ', options)
      const { awardType, year, action, formName, part, name, id, data } = options
      let URL = context.state.baseUrl + `projekt/function/${awardType}/${year}/${formName}/${part}/${name}/${id}`
      try {
        const response = await fetch(URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
          body: JSON.stringify(data),
        })
        const result = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !result) {
          throw new Error('error posting remote function')
        } else {
          return result
        }
      } catch (error) {
        console.error('postRemoteFunction error -> ', (error as any).message)
      }
    },

    /**
     * Creates project draft
     * @param context
     * @param data
     */
    async createDraft(context: any, data: any) {
      const { awardType, year } = data
      const baseUrl = context.state.baseUrl
      const formName = 'user_create_draft'
      const part = 'part1'
      try {
        const response = await fetch(`${baseUrl}projekt/${awardType}/${year}/${formName}/${part}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
          body: JSON.stringify({}),
        })
        const project = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !project) {
          throw new Error('error creating draft')
        } else {
          return project
        }
      } catch (error) {
        console.error('getProjekte error -> ', (error as any).message)
      }
    },

    /**
     * Creates project draft for new award
     * @param context
     * @param data
     */
    async createDraftFrom(context: any, data: any) {
      const { awardType, year, id } = data
      const baseUrl = context.state.baseUrl
      const formName = 'user_create_draft'
      const part = 'part1'
      const url = `${baseUrl}projekt/${awardType}/${year}/${formName}/${part}/${id}`
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
          body: JSON.stringify({}),
        })
        const project = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !project) {
          throw new Error('error creating draft from existing project')
        } else {
          return project
        }
      } catch (error) {
        console.error('getProjekte error -> ', (error as any).message)
      }
    },

    /**
     * Saves project
     * @param context
     * @param data
     */
    async saveProject(context: any, data: any) {
      const baseUrl = context.state.baseUrl
      const { awardType, year, formName, id, part, formData, silent } = data
      let URL = `${baseUrl}projekt/${awardType}/${year}/${formName}/${part}/${id}`
      if (silent) URL += '?autosave=1'
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-AUTH-TOKEN': context.state.user.apiToken
        },
        body: JSON.stringify(formData),
      })
      const project = await response.json()
      if (response.status === 401) {
        context.dispatch('userNotAuthenticated', response)
        throw new Error('user not authenticated')
      } else if (response.status >= 400 || !project) {
        if (project) {
          throw new Error(JSON.stringify(project))
        } else {
          throw new Error('error saving project')
        }
      } else {
        return project
      }
    },

    /**
     * Deletes a project
     * @param context
     * @param id
     */
    async deleteProject(context: any, id: any) {
      try {
        const response = await fetch(context.state.baseUrl + `projekt/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const project = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400) {
          throw new Error('error deleting project')
        } else {
          return project
        }
      } catch (error) {
        console.error('deleteProject error -> ', (error as any).message)
      }
    },

    /**
     * Deletes a project detail
     * @param context
     * @param id
     * @param award
     */
    async deleteProjectDetail(context: any, data: any) {
      try {
        const { id, year, awardType } = data
        const response = await fetch(context.state.baseUrl + `projekt/${awardType}/${year}/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const project = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400) {
          throw new Error('error deleting projectDetail')
        } else {
          return project
        }
      } catch (error) {
        console.error('deleteProjectDetail error -> ', (error as any).message)
      }
    },

    /**
     * Saves a file
     * @param context
     * @param data
     */
    async saveFile(context: any, data: any) {
      const baseUrl = context.state.baseUrl
      const formName = 'user_project_save'
      const { awardType, year } = data
      const { id, part, fieldName, file } = data
      const formData = new FormData()
      formData.append('file', file)
      const defaultUrl = `file/upload/pdf/projekt/${id}/${awardType}/${year}/${formName}/${part}/${fieldName}`
      const URL = `${baseUrl}` + (data.URL ? data.URL : defaultUrl)
      try {
        const response = await fetch(URL, {
          method: 'POST',
          headers: {
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
          body: formData,
        })
        const file = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !file) {
          throw new Error('error posting image')
        } else {
          return file
        }
      } catch (error) {
        console.error('saveFile error -> ', (error as any).message)
      }
    },

    /**
     * Saves an image
     * @param context
     * @param data
     */
    async saveImage(context: any, data: any) {
      const baseUrl = context.state.baseUrl
      const formName = 'user_project_save'
      const { awardType, year } = data
      const { id, part, fieldName, file } = data
      const formData = new FormData()
      formData.append('file', file)

      if (data.fg_ort != undefined) formData.append('fg_ort', data.fg_ort)
      if (data.titel != undefined) formData.append('titel', data.titel)
      if (data.fg_vorname != undefined) formData.append('fg_vorname', data.fg_vorname)
      if (data.fg_zuname != undefined) formData.append('fg_zuname', data.fg_zuname)

      const defaultUrl = `file/upload/image/projekt/${id}/${awardType}/${year}/${formName}/${part}/${fieldName}`
      const URL = `${baseUrl}` + (data.URL ? data.URL : defaultUrl)
      try {
        const response = await fetch(URL, {
          method: 'POST',
          headers: {
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
          body: formData,
        })
        const img = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !img) {
          console.error('error posting image')
          throw new Error(JSON.stringify(img))
        } else {
          return img
        }
      } catch (error) {
        if ((error as any).message.startsWith('{')) {
          const errors = JSON.parse((error as any).message)
          console.error('saveImage error -> ', errors)
          let messages: string[] = []
          if (errors.errorObject) {
            errors.errorObject.forEach((err: any) => {
              switch (err.error) {
                case 'minWidth':
                  messages.push('Die Auflösung des Bilds in der Breite ist zu niedrig')
                  break;
                case 'maxWidth':
                  messages.push('Die Auflösung des Bilds in der Breite ist zu hoch')
                  break;
                case 'minHeight':
                  messages.push('Die Auflösung des Bilds in der Höhe ist zu niedrig')
                  break;
                case 'maxHeight':
                  if (err.aspectRatio) {
                    messages.push('Das Seitenwerhältnis des Bilds soll ' + err.aspectRatio + ' betragen')
                  } else {
                    messages.push('Die Auflösung des Bilds in der Höhe ist zu hoch')
                  }
                  break;

                default:
                  break;
              }
            });
          }
          context.commit('setAlert', {
            type: 'error',
            message: 'Fehler beim Hochladen: <br> ' + messages.join('. ') + '.',
          })
        } else {
          console.error('saveImage error ->', error)
        }
      }
    },

    /**
     * Deletes a file
     * @param context
     * @param data
     */
    async deleteFile(context: any, data: any) {
      const baseUrl = context.state.baseUrl
      const { id, fieldName } = data
      const URL = `${baseUrl}pdf/awards/${id}/${fieldName}`
      try {
        const response = await fetch(URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const file = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !file) {
          throw new Error('error deleting file')
        } else {
          return file
        }
      } catch (error) {
        console.error('deleteFile error -> ', (error as any).message)
      }
    },

    /**
     * Deletes an image
     * @param context
     * @param data
     */
    async deleteImage(context: any, data: any) {
      const baseUrl = context.state.baseUrl
      const { id, fieldName, type, awardType, year, formName, part } = data
      let URL = `${baseUrl}file/image/${type}/${id}/${fieldName}`
      if (type == 'projekt' && fieldName.includes('image_detail')) {
        URL = `${baseUrl}file/image/${type}/${awardType}/${year}/${id}/${fieldName}`
      }
      if (type != 'projekt') {
        URL = `${baseUrl}file/delete/image/${type}/${id}/${formName}/${part}/${fieldName}`
      }
      try {
        const response = await fetch(URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const img = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !img) {
          throw new Error('error deleting image')
        } else {
          return img
        }
      } catch (error) {
        console.error('deleteImage error -> ', (error as any).message)
      }
    },

    /**
     * Fetches user's office
     * @param context
     */
    async getOffice(context: any) {
      try {
        const response = await fetch(context.state.baseUrl + `office`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
        })
        const office = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400) {
          throw new Error('error fetching office')
        } else {
          return office
        }
      } catch (error) {
        console.error('getOffice error -> ', (error as any).message)
      }
    },

    /**
     * Saves a user
     * @param context
     * @param data
     */
    async saveUserCore(context: any, data: any) {
      try {
        const { formData, id } = data
        const response = await fetch(context.state.baseUrl + `user/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.state.user.apiToken
          },
          body: JSON.stringify(formData),
        })
        const user = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !user) {
          throw new Error('error saving user')
        } else {
          return user
        }
      } catch (error) {
        console.error('saveUserCore error -> ', (error as any).message)
      }
    },

    /**
     * Saves a user office data
     * @param context
     * @param data
     */
    async saveUserOffice(context: any, data: any) {
      console.log('saveUserOffice: ', data);
      const { formName, currentPart, formData, year, awardType } = data
      const response = await fetch(context.state.baseUrl + `office/${awardType}/${year}/${formName}/${currentPart}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-AUTH-TOKEN': context.state.user.apiToken
        },
        body: JSON.stringify(formData),
      })
      const office = await response.json()
      if (response.status === 401) {
        context.dispatch('userNotAuthenticated', response)
        throw new Error('user not authenticated')
      } else if (response.status >= 400 || !office) {
        if (office) {
          throw new Error(JSON.stringify(office))
        } else {
          throw new Error('error saving user office')
        }
      } else {
        return office
      }
    },

  },
  modules: {
    awards: awardModule,
    projects: projectModule,
    sponsors: sponsorModule,
    users: userModule,
  },
  plugins: [vuexLocal.plugin]
})

export default store
