<template>
  <div id="footer" v-if="!fullscreenMode">
    <div class="footer-wrapper">
      <div v-if="!darkMode" :class="[$mq !== 'sm' ? 'col-24 left' : 'pl-2']">
        <img class="mt-1" src="../assets/img/logo.svg" alt="Bayerische Architektenkammer" />
      </div>
      <div v-else class="col-12 left invisible">Bayerische Architektenkammer</div>
      <div :class="[$mq !== 'sm' ? 'col-20 left' : 'pl-2']">
        <template v-if="user">
          <span v-if="isAdmin">
            <template v-if="!exportMode">
              <router-link to="/export/index">Export</router-link><br>
            </template>
            <template v-if="!juryMode">
              <router-link to="/jury/index">Jury</router-link><br>
            </template>
            <template v-if="!pressMode">
              <router-link to="/presse/index">Presse</router-link><br>
            </template>
            <router-link to="/admin">Administration</router-link><br>
          </span>
          <span v-if="!exportMode && !juryMode && !pressMode">
            <router-link to="/">Start</router-link><br>
          </span>
          <span v-if="!user">
            <router-link to="/anmelden">Anmelden</router-link><br>
          </span>
          <span v-else-if="exportMode">
            <router-link to="/export/index">Übersicht</router-link><br>
            <router-link to="/export/abmelden">Abmelden</router-link>
          </span>
          <span v-else-if="juryMode">
            <router-link to="/jury/index">Übersicht</router-link><br>
            <router-link to="/jury/abmelden">Abmelden</router-link>
          </span>
          <span v-else-if="pressMode">
            <router-link to="/presse/index">Übersicht</router-link><br>
            <router-link to="/presse/abmelden">Abmelden</router-link>
          </span>
          <span v-else>
            <router-link to="/anmelden/index">Übersicht</router-link><br>
            <router-link to="/anmelden/abmelden">Abmelden</router-link>
          </span>
        </template>
      </div>

      <div :class="[$mq !== 'sm' ? 'col-45 left' : 'pl-2']">
        <a target="_blank" href="https://www.bim.bayern.de/impressum/" rel="noopener">Impressum</a><br>
        <a target="_blank" href="https://www.bim.bayern.de/datenschutz/" rel="noopener">Datenschutzbestimmungen</a><br>
      </div>
      
      <div :class="[$mq !== 'sm' ? 'col-10 left' : 'right pr-2']">
        <transition name="fade">
          <a v-if="isScrolled" href="" @click.stop.prevent="scrollToTop">
            <unicon name="angle-up" width="16" height="16"></unicon>
            nach oben
          </a>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  data() {
    return {
      isScrolled: false,
    }
  },

  props: {
    msg: String,
  },

  computed: {
    isAdmin () { 
      return this.$store.state.user?.roles?.includes('ROLE_ADMIN')
    },
    isExport () { 
      return this.$store.state.user?.roles?.includes('ROLE_EXPORT')
    },
    isJury () { 
      return this.$store.state.user?.roles?.includes('ROLE_JURY')
    },
    isPress () { 
      return this.$store.state.user?.roles?.includes('ROLE_PRESS')
    },
    darkMode () { 
      return this.$store.state.darkMode
    },
    exportMode () { 
      return this.$store.state.exportMode
    },
    fullscreenMode () { 
      return this.$store.state.fullscreenMode
    },
    juryMode () { 
      return this.$store.state.juryMode
    },
    pressMode () { 
      return this.$store.state.pressMode
    },
    user () { 
      return this.$store.state.user
    },
  },

  methods: {
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    setIsScrolled () {
      this.isScrolled = window.scrollY > 10
    },
  },

  mounted () {
    window.addEventListener('scroll', this.setIsScrolled, { passive: true })
    this.setIsScrolled()
  },

  destroyed () {
    window.removeEventListener('scroll', this.setIsScrolled, { passive: true })
  },
}
</script>

<style lang="less">
#footer {
  /*position: relative;*/
  width: 100%;
  margin: 40px 0 0;
  padding: 20px 0;
  line-height: 1.45;
  text-align: left;
  overflow: hidden;
  background-color: rgba(192, 192, 192, 0.05);
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.33);

  .footer-wrapper {
    max-width: 1000px;
    min-height: 120px;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
    color: inherit !important;
  }
}

@media all and (min-width: 1000px) {
  #footer {
    width: 120%;
    margin: 40px -10% 0;
    padding: 20px 0;
    min-height: 145px;
  }
}

</style>
