
import Vue from 'vue'

export default Vue.extend({
  name: 'ByakTile',

  props: [
    'icon',
    'inactive',
    'isTab',
    'to',
    'text',
  ],

  methods: {
    goTo () {
      const link = this.$refs.link as Vue
      if (link) (link.$el as HTMLElement).click()
    },
  },
})
