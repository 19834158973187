
import Vue from 'vue'
import { getOptions } from '../../mixins/formData'

export default Vue.extend({
  name: 'FormCheckboxSubs',

  data: () => {
    const array: any[] = []
    const conditions: any = {}
    const names: any = {}
    const subArrays: any = {}
    return {
      array,
      conditions,
      names,
      subArrays,
    };
  },

  props: [
    'description',
    'errors',
    'field',
    'fields',
    'label',
    'name',
    'options',
    'subs',
    'validation',
    'value',
  ],

  computed: {
    appInfo () {
      return (this.$store as any).state.appInfo
    },
  },

  methods: {
    checkCondition(condition: any, o: any) {
      if (condition.values && o.value) {
        if (condition.values.filter((v: string) => v === o.value).length > 0) {
          return true
        }
      } else {
        return false
      }
    },

    checkErrors(name: string, value: string, checkVal: any = false) {
      let altClasses: any = false
      if (this.errors && this.errors.length > 0) {
        let e = this.errors.filter((err: any) => {
          if (this.array.length > 0 && err.field && err.field.indexOf(this.name) >= 0) {
            var vIndex = this.array.indexOf(value)
            if (vIndex >= 0 && err.field.indexOf(this.name + '[' + vIndex + ']') >= 0) {
              return true
            }
          }
          if (err.field && err.field.indexOf(name) >= 0 && err.field.indexOf(value) >= 0) {
            return true
          }
          if (err.field && err.field.indexOf(name) >= 0 && err.field.indexOf('[subs]') >= 0) {
            const test = value.split('_')
            const v = test[test.length - 1]
            if (err.field.indexOf('[' + v + ']') >= 0) {
              return true
            }
          }
          if (checkVal && err.error === checkVal) {
            altClasses = checkVal
          } else if (checkVal) {
            altClasses = 'none'
          }
          return false
        })
        if (e.length > 0) return altClasses || 'error'
      }
    },

    emitInput() {
      this.$emit('input', this.array.map((o: any) => {
        let subs
        if (this.subArrays[o]) {
          // console.log('emitInput this.subArrays['+o+']: ', this.subArrays[o]);
          subs = []
          this.subArrays[o].forEach((s: any) => {
            console.log('this.names: ', s, this.names);
            const res = {
              selected: true,
              name: this.names[s],
              value: s,
              text: this.conditions[s] || ''
            }
            subs.push(res)
          })
        }
        const res = {
          selected: true,
          name: this.names[o],
          value: o,
          subs,
          text: this.conditions[o] || ''
        }
        return res
      }))
    },

    emitSubcheckbox(option: any, el: any) {
      // console.log('emitSubcheckbox this.subArrays['+option.value+']: ', this.subArrays[option.value], el.value);
      // toggle in array
      if (this.subArrays[option.value].includes(el.value)) {
        const index = this.subArrays[option.value].indexOf(el.value);
        this.subArrays[option.value].splice(index, 1)
      } else {
        this.subArrays[option.value].push(el.value)
      }
      this.subArrays = Object.assign({}, this.subArrays)
      this.emitInput()
    },

    emitSubfield(field: string, option: any, el: any) {
      this.conditions[option.value] = el.value
      this.conditions = Object.assign({}, this.conditions)
      this.emitInput()
    },

    getVal(option: any) {
      let res = option.value
      if (typeof res === 'undefined') {
        res = option.name
      }
      if (res && typeof res.toString === 'function') {
        res = res.toString()
      }
      return res
    },

    isChecked(option: any, array: any) {
      let val = this.getVal(option)
      if (array) {
        return array.includes(val)
      } else {
        return this.value == val
      }
    },

    updateInput(el: any) {
      setTimeout(() => {
        const valid = this.validation
        if (valid && valid.requiredCollectionSizeMin === 1 && valid.requiredCollectionSizeMax === 1) {
          this.array = [el.value]
        }

        // this is a checkbox with additional fields
        // console.log('updateInput value: ', el.value);
        this.emitInput()
      }, 20)
    },

    getOptions,
  },

  beforeMount () {
    if (this.value && this.subs) {
      // map incoming subfields and values, make sure they're unique
      this.value.forEach((v: any) => {
        // console.log('v: ', v);
        this.array.push(v.value)
        if (v.subs) {
          v.subs.forEach((s: any) => {
            // console.log('s: ', s);
            if (!this.subArrays[v.value]) {
              this.subArrays[v.value] = []
            }
            this.subArrays[v.value].push(s.value)
          });
        }
      })

      this.options.forEach((o: any) => {
        this.names[o.value] = o.name
      })
      Object.keys(this.subs).forEach((k: string) => {
        if (!this.subArrays[k]) {
          this.subArrays[k] = []
        }
        const v = this.subs[k]
        if (v.values) {
          const opts = this.getOptions(v, this.appInfo)
          opts.forEach((opt: any) => {
            // names for subArray Options
            this.names[opt.value] = opt.name
          });
        }
      })
      this.conditions = {}
      this.value.forEach((v: any) => {
        this.conditions[v.value] = v.text
        if (v.subs) {
          v.subs.forEach((s: any) => {
            this.conditions[s.value] = s.text
          })
        }
      })
    }
  },
})
