
import Vue from 'vue'

export default Vue.extend({
  name: 'Award',

  data: () => {
    return {
      show: true,
    };
  },

  props: [
    'active',
    'award',
    'onClick',
  ],

  methods: {
    close() {
      this.show = false;
    }
  },
})
