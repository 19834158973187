
import Vue from 'vue'

export default Vue.extend({
  name: 'ProgressBar',

  props: [
    'count',
    'sel',
    'total',
  ],

  computed: {
    val1 () {
      if (this.total == 0) return 0
      return Math.round(this.sel / this.total * 100)
    },
    val2 () {
      if (this.total == 0) return 0
      return Math.round(this.count / this.total * 100)
    },
    percent () {
      if (this.total == 0) return 0
      return Math.round(this.sel / this.count * 100)
    },
  }
})
