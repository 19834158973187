
import Vue from 'vue'

export default Vue.extend({
  name: 'FormRadio',

  data: () => {
    return {
      err: '',
    };
  },

  props: [
    'description',
    'errors',
    'field',
    'label',
    'name',
    'options',
    'val',
    'validation',
    'value',
  ],

  computed: {
    reloadTriggers () {
      return this.$store.state.reloadTriggers
    },
  },

  methods: {
    checkErrors(name: string) {
      if (this.errors && this.errors.length > 0) {
        let e = this.errors.filter((err: any) => {
          if (err.field && err.field.startsWith(name)) {
            // console.log('err.field: ', err.field)
            return true
          }
          return false
        })
        if (e.length > 0) return 'error'
      }
    },

    getVal(option: any) {
      let res
      if (typeof option === 'object') {
        res = option.value
        if (typeof res === 'undefined') {
          res = option.name
        }
      } else {
        res = this.val
      }
      if (typeof res.toString === 'function') {
        res = res.toString()
      }
      return res
    },

    isChecked(option: any) {
      let val1 = this.getVal(option)
      let val2 = this.value
      if (typeof val2 === 'boolean') {
        val2 = val2.toString()
      }
      return val2 == val1
    },

    updateInput(el: any) {
      setTimeout(() => {
        let val = el.value
        if (val === 'true') val = true
        if (val === 'false') val = false
        this.$emit('input', val)

        if (this.reloadTriggers.includes(this.name)) {
          this.$store.commit('triggerSaveForm', true)
        }
      }, 100)
    },
  },
})
