/* tslint:disable:no-console */
import Vue from 'vue';

export const sponsorModule: any = {
  state: {
    currentSponsor: null,
    sponsorCount: 0,
    sponsors: [],
  },
  mutations: {
    setSponsors(state: any, data: any) {
      state.sponsors = data.items;
      state.sponsorCount = data.count;
    },
    setSponsor(state: any, sponsor: any) {
      state.currentSponsor = sponsor;
    },
  },
  actions: {
    /**
     * adds a sponsor
     * @param context
     * @param data
     */
    async addSponsor(context: any, data: any) {
      console.log('addSponsor: ', data);
      try {
        let URL = context.rootState.baseUrl + 'crud/awardsponsor'
        const response = await fetch(URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
          body: JSON.stringify(data),
        })
        const sponsor = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !sponsor) {
          throw new Error('error creating sponsor')
        } else {
          return sponsor
        }
      } catch (error) {
        console.error('addSponsor error -> ', (error as any).message)
      }
    },

    /**
     * saves an edited sponsor
     * @param context
     * @param data
     */
    async saveSponsor(context: any, data: any) {
      const { id, formData } = data
      console.log('saveSponsor -> id, formData: ', id, formData);
        let URL = context.rootState.baseUrl + 'crud/awardsponsor/' + id
        const response = await fetch(URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
          body: JSON.stringify(formData),
        })
        const sponsor = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !sponsor) {
          if (sponsor) {
            throw new Error(JSON.stringify(sponsor))
          } else {
            throw new Error('error saving sponsor')
          }
        } else {
          context.commit('setSponsor', sponsor)
        }
    },

    /**
     * fetches a sponsor from cache
     * @param context
     * @param id
     */
    fetchSponsor(context: any, id: any) {
      if (context.state.sponsors.length < 1) {
        context.dispatch('getSponsors')
      }
      return context.state.sponsors.filter((u: any) => u.id == id)[0]
    },

    /**
     * fetches a sponsor
     * @param context
     * @param id
     */
    async getSponsor(context: any, id: any) {
      try {
        let URL = context.rootState.baseUrl + 'crud/awardsponsor/' + id
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const sponsor = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !sponsor) {
          throw new Error('error fetching sponsor')
        } else {
          // context.commit('setSponsor', sponsor)
          return sponsor
        }
      } catch (error) {
        console.error('getSponsor error -> ', (error as any).message)
      }
    },

    /**
     * fetches all sponsors
     * @param context
     * @param query
     */
    async getSponsors(context: any, query: string = '') {
      try {
        let URL = context.rootState.baseUrl + 'crud/awardsponsor/list' + query
        const response = await fetch(URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const sponsors = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400 || !sponsors) {
          throw new Error('error fetching sponsors')
        } else {
          context.commit('setSponsors', sponsors)
          return sponsors
        }
      } catch (error) {
        console.error('getSponsors error -> ', (error as any).message)
      }
    },

    /**
     * permanently deletes a sponsor from database
     * @param context
     * @param id
     */
    async deleteSponsor(context: any, id: any) {
      try {
        let URL = context.rootState.baseUrl + 'crud/awardsponsor/' + id
        const response = await fetch(URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': context.rootState.user.apiToken
          },
        })
        const sponsor = await response.json()
        if (response.status === 401) {
          context.dispatch('userNotAuthenticated', response)
          throw new Error('user not authenticated')
        } else if (response.status >= 400) {
          throw new Error('error deleting sponsor')
        } else {
          return sponsor
        }
      } catch (error) {
        console.error('deleteSponsor error -> ', (error as any).message)
      }
    },
  },
};
