
import Vue from 'vue'

export default Vue.extend({
  name: 'CrudItem',

  props: [
    'del',
    'edit',
    'goto',
    'icon',
    'inactive',
    'item',
    'selectedAward',
    'type',
  ],

  data: () => {
    return {
      cols: {
        awards: [
          {value: 'id', name: 'ID'},
          {value: 'title', name: 'Titel'},
          {value: 'awardType', name: 'Kategorie'},
          {value: 'year', name: 'Jahr'},
          {value: 'active', name: 'Aktiv'},
          {value: 'projects', name: 'Projekte'},
        ],
        projects: [
          // {value: 'titel', name: 'Titel'},
          {value: 'baujahr', name: 'Baujahr'},
          {value: 'bauherr_name', name: 'Bauherr'},
          {value: 'typologie', name: 'Typologie'},
          {value: 'reg_bezirk', name: 'Regierungsbezirk'},
          {value: 'userId', name: 'Benutzer'},
        ],
        sponsors: [
          {value: 'id', name: 'ID'},
          {value: 'name', name: 'Name'},
        ],
        users: [
          {value: 'id', name: 'ID'},
          {value: 'firstname', name: 'Vorname'},
          {value: 'lastname', name: 'Nachname'},
          {value: 'email', name: 'E-Mail'},
          // {value: 'officeName', name: 'Büro'},
          {value: 'roles', name: 'Rechte'},
          {value: 'projectCount', name: 'Projekte'},
        ],
      },
      showDetail: {} as any,
      user: {} as any,
    }
  },

  computed: {
    awards () {
      return this.$store.state.awards?.awards
    },
    allAwards () {
      return this.$store.state.awards?.allAwards
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward(): any {
      return this.$store.state.currentAward
    },
    pdfDocuments(): any {
      return this.$store.state.appInfo?.awards?.pdfDocuments
    },
  },

  methods: {
    awardTypeTitle (award: string) {
      if (award && this.$store.state.appInfo) {
        return this.$store.state.appInfo.meta.awardTypes[award]
      }
      return ''
    },

    getAward (awardType: string, year: string) {
      return this.allAwards.filter((a: any) => a.awardType == awardType && a.year == year)[0]
    },

    getGoto (obj: any) {
      switch (obj.type) {
        case 'awards':
          return '/admin/liste/projects/' + obj.item.id
        case 'projects':
          return '/admin/bearbeiten/users/' + obj.item.userId
        default:
          return '/admin/liste/projects'
      }
    },

    getGotoIcon (obj: any) {
      switch (obj.type) {
        case 'projects':
          return 'user'
        default:
          return 'list-ul'
      }
    },

    getGotoTitle (obj: any) {
      switch (obj.type) {
        case 'projects':
          return 'Benutzer anzeigen'
        default:
          return 'zu Projekten wechseln'
      }
    },

    getPdfPath(p: any, pd: any, type: string) {
      const award = this.getAward(pd.awardType, pd.year)
      const { awardType, year } = award
      let url = this.baseUrl.slice(0, -1) + this.pdfDocuments[awardType][year][type].url
      url = url.replace('-AWARDTYPE-', awardType)
      url = url.replace('-YEAR-', year)
      url = url.replace('-ID-', p.id)
      return url
    },

    getValue (key: string) {
      const obj = this.item
      const val = obj[key]
      let res = obj[key]
      if (!obj) return
      switch (key) {
        case 'awardType':
          if (obj._header) return res
          return this.awardTypeTitle(val)
        case 'officeName':
          return (obj.related && obj.related.office) ? obj.related.office.name : ' - '
        case 'roles':
          return (obj.related && obj.related.roles) ? obj.related.roles.name : obj.roles.join(', ').replaceAll('ROLE_', '')
        case 'projectCount':
          return (obj.related && obj.related.projekte) ? obj.related.projekte.length : '0'
        case 'projects':
          return (obj.related && obj.related.projekteCount) ? obj.related.projekteCount : '0'
        case 'typologie':
          if (obj._header) return res
          res = this.$store.state.appInfo.meta.projectCategories?.Typologie.filter((t: any) => t.value == val)
          return res.length > 0 ? res[0].name : obj[key]
        case 'reg_bezirk':
          if (obj._header) return res
          res = this.$store.state.appInfo.meta.projectCategories?.Bezirk.filter((t: any) => t.value == val)
          return res.length > 0 ? res[0].name : obj[key]
        case 'titel':
          return val ? val : '- kein Titel -'
        case 'userId':
          return (obj.related && obj.related.user) ? obj.related.user.firstname ?
                 obj.related.user.firstname + ' ' + obj.related.user.lastname :
                 obj.related.user.username  : val
        default:
          return res
      }
    },

    getText () {
      let obj = this.item
      let tmp = ''
      switch (this.type) {
        case 'users':
          tmp = obj.firstname && obj.lastname ? obj.firstname + ' ' + obj.lastname : obj.email
          return tmp + ' (ID:' + obj.id + ')'
        case 'awards':
          return obj.title
        default:
          return obj.titel + ', ' + obj.bauherr_name + ' (' + obj.baujahr + ')'
      }
    },

    pdGoTo (pd: any, to: any) {
      return {
        name: to.name,
        params: {
          type: 'projectDetails',
          id: pd.id,
          }
      }
    },

    switchContextAndGoTo (pd: any, to: any = null) {
      const { awardType, year } = pd
      // console.log('switchContextAndGoTo: ', awardType, year);
      if (!awardType || !year) {
        return this.$store.commit('setAlert', {
          type: 'error',
          message: 'Für dieses ProjektDetail konnte keine passende Kampagne gefunden werden. Typ ' + awardType + ', Jahr' + year,
        })
      }

      let res = this.allAwards?.filter((aw: any) => (aw.awardType == awardType && aw.year == year))[0]
      // console.log('res: ', res);
      if (res) {
        this.$store.commit('setCurrentAward', res)
        // update router info for projectDetail
        if (!to) return
        if (to.name === 'crud-entfernen') {
          return this.$router.push(this.pdGoTo(pd, to))
        }
        return this.$router.push(to)
      }
    },

    toggleDetail (id: string, val?: boolean) {
      let tmp = JSON.parse(JSON.stringify(this.showDetail))
      this.showDetail[id] = val ? val : !tmp[id]
      this.showDetail = Object.assign({}, this.showDetail)
    },
  },

  mounted () {
    if (this.type === 'projects') {
      if (this.item.projectDetails) {
        this.item.projectDetails.forEach((pd: any) => {
          if (
            this.selectedAward &&
            this.selectedAward.awardType === pd.awardType &&
            this.selectedAward.year == pd.year
          ) {
            this.showDetail[pd.id] = true
            this.showDetail = Object.assign({}, this.showDetail)
          }
        })
      }
    }
  },
})
